<template>

    <h1 class="text-center">Navigations</h1>


</template>

<script>
export default {
  name: "Navigations"
}
</script>

<style scoped>

</style>